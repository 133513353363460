// questions.js
export const questions = {
  short: [
    {
      text: "The government should actively regulate and intervene in the economy.",
      weight: -1,
      proView: "Government regulation protects public interests, prevents monopolies, and ensures economic stability.",
      conView: "Free markets are more efficient and government intervention stifles innovation and economic growth.",
      policy: "Economic System & Market Regulation",
      example: "Example: Antitrust laws vs. free market competition"
    },
    {
      text: "Individual rights should take priority over collective needs.",
      weight: 1,
      proView: "Personal liberty is fundamental and should be protected from government overreach.",
      conView: "The collective good sometimes requires limiting individual freedoms.",
      policy: "Rights & Individual Liberty",
      example: "Example: Personal privacy rights vs. public security measures"
    },
    {
      text: "Essential public services should be primarily government-provided.",
      weight: -1,
      proView: "Basic services like healthcare and education should be accessible to all through public funding.",
      conView: "Private sector provision leads to better quality and efficiency in services.",
      policy: "Public Services & Social Safety",
      example: "Example: Public healthcare system vs. private healthcare"
    },
    {
      text: "A strong military and security apparatus is necessary for national safety.",
      weight: 1,
      proView: "Robust defense and security forces are essential for protecting citizens and national interests.",
      conView: "Excessive security spending diverts resources from more pressing social needs.",
      policy: "Security & Defense",
      example: "Example: Increased defense budget vs. diplomatic initiatives"
    },
    {
      text: "Environmental protection should be prioritized over economic growth.",
      weight: -1,
      proView: "Long-term environmental sustainability is crucial for human survival and prosperity.",
      conView: "Economic development should take precedence over environmental regulations.",
      policy: "Environmental & Resource Management",
      example: "Example: Strict emissions controls vs. industrial growth"
    }
  ],
  medium: [
    // First 5 Core Policies
    {
      text: "The government should actively regulate and intervene in the economy.",
      weight: -1,
      proView: "Government regulation protects public interests, prevents monopolies, and ensures economic stability.",
      conView: "Free markets are more efficient and government intervention stifles innovation and economic growth.",
      policy: "Economic System & Market Regulation",
      example: "Example: Antitrust laws vs. free market competition"
    },
    {
      text: "Individual privacy rights should be protected even if they limit security measures.",
      weight: 1,
      proView: "Personal privacy is a fundamental right that should not be compromised.",
      conView: "Public safety sometimes requires certain limitations on privacy.",
      policy: "Rights & Individual Liberty",
      example: "Example: Data privacy vs. surveillance for security"
    },
    {
      text: "Universal healthcare should be provided by the government.",
      weight: -1,
      proView: "Healthcare is a basic human right that should be accessible to all regardless of wealth.",
      conView: "Private healthcare systems provide better quality and innovation.",
      policy: "Public Services & Social Safety",
      example: "Example: National health service vs. private insurance"
    },
    {
      text: "Defense spending should be a top budget priority.",
      weight: 1,
      proView: "Military strength ensures national security and global stability.",
      conView: "Military spending should be reduced in favor of social programs.",
      policy: "Security & Defense",
      example: "Example: Military modernization vs. social investment"
    },
    {
      text: "Strict environmental regulations should be enforced on industries.",
      weight: -1,
      proView: "Environmental protection requires strong government oversight and regulation.",
      conView: "Environmental protection should be driven by market incentives, not regulation.",
      policy: "Environmental & Resource Management",
      example: "Example: Mandatory emissions limits vs. carbon trading"
    },
    // Next 5 Important Policies
    {
      text: "Immigration policies should prioritize economic needs over humanitarian concerns.",
      weight: 1,
      proView: "Immigration should be based on skills and economic contribution.",
      conView: "Humanitarian considerations should guide immigration policy.",
      policy: "Immigration & Demographics",
      example: "Example: Merit-based vs. humanitarian-based immigration"
    },
    {
      text: "Education should be primarily funded and managed by the government.",
      weight: -1,
      proView: "Public education ensures equal access and standardized quality.",
      conView: "Private and charter schools offer better educational choices.",
      policy: "Education & Innovation",
      example: "Example: Public schools vs. school choice programs"
    },
    {
      text: "Government should maintain a balanced budget even during economic downturns.",
      weight: 1,
      proView: "Fiscal responsibility prevents long-term debt problems.",
      conView: "Deficit spending is necessary for economic stability in downturns.",
      policy: "Monetary & Fiscal Policy",
      example: "Example: Balanced budget vs. stimulus spending"
    },
    {
      text: "Workers should have strong union rights and protections.",
      weight: -1,
      proView: "Strong unions protect workers' rights and ensure fair compensation.",
      conView: "Excessive union power can harm economic flexibility and growth.",
      policy: "Labor & Employment",
      example: "Example: Strong union rights vs. right-to-work laws"
    },
    {
      text: "International trade should be as free as possible with minimal restrictions.",
      weight: 1,
      proView: "Free trade promotes economic growth and global development.",
      conView: "Trade should be regulated to protect domestic industries and workers.",
      policy: "Trade & International Relations",
      example: "Example: Free trade agreements vs. protectionist policies"
    }
  ],
  detailed: [
    // Economic System & Market Regulation
    {
      text: "The government should break up large corporations to prevent monopolies.",
      weight: -1,
      proView: "Market concentration leads to abuse of power and requires government intervention.",
      conView: "Large companies achieve efficiencies that benefit consumers and the economy.",
      policy: "Economic System & Market Regulation",
      example: "Example: Breaking up tech giants vs. allowing market consolidation"
    },
    {
      text: "Financial institutions should operate with minimal government oversight.",
      weight: 1,
      proView: "Market discipline is more effective than government regulation.",
      conView: "Financial regulation is necessary to prevent economic crises.",
      policy: "Economic System & Market Regulation",
      example: "Example: Deregulated banking vs. strict financial oversight"
    },

    // Rights & Individual Liberty
    {
      text: "Personal data privacy should be protected by strict government regulations.",
      weight: -1,
      proView: "Government must protect individual privacy in the digital age.",
      conView: "Data sharing enables innovation and improved services.",
      policy: "Rights & Individual Liberty",
      example: "Example: Strict data protection laws vs. business-friendly regulations"
    },
    {
      text: "Government surveillance powers should be expanded for national security.",
      weight: 1,
      proView: "Enhanced surveillance is necessary to prevent threats.",
      conView: "Mass surveillance violates civil liberties and privacy rights.",
      policy: "Rights & Individual Liberty",
      example: "Example: Expanded police powers vs. civil liberties protections"
    },

    // Public Services & Social Safety
    {
      text: "The government should provide universal basic income.",
      weight: -1,
      proView: "UBI ensures basic dignity and economic security for all.",
      conView: "UBI reduces incentive to work and is financially unsustainable.",
      policy: "Public Services & Social Safety",
      example: "Example: Universal basic income vs. targeted welfare"
    },
    {
      text: "Healthcare services should be primarily private and market-driven.",
      weight: 1,
      proView: "Private healthcare promotes innovation and efficiency.",
      conView: "Market-based healthcare creates inequalities in access.",
      policy: "Public Services & Social Safety",
      example: "Example: Private insurance vs. universal healthcare"
    },

    // Security & Defense
    {
      text: "Military intervention is justified to protect human rights abroad.",
      weight: 1,
      proView: "Military force can prevent humanitarian catastrophes.",
      conView: "Military intervention often causes more harm than good.",
      policy: "Security & Defense",
      example: "Example: Humanitarian intervention vs. non-intervention"
    },
    {
      text: "Defense spending should be significantly reduced.",
      weight: -1,
      proView: "Military spending diverts resources from crucial social needs.",
      conView: "Strong defense spending is essential for national security.",
      policy: "Security & Defense",
      example: "Example: Reduced military budget vs. maintained strength"
    },

    // Environmental & Resource Management
    {
      text: "Carbon emissions should be taxed heavily.",
      weight: -1,
      proView: "Carbon pricing is necessary to combat climate change.",
      conView: "Carbon taxes hurt economic growth and jobs.",
      policy: "Environmental & Resource Management",
      example: "Example: Carbon tax vs. voluntary reduction"
    },
    {
      text: "Environmental regulations should be relaxed to promote economic growth.",
      weight: 1,
      proView: "Environmental regulations often impose unnecessary costs.",
      conView: "Environmental protection is crucial for long-term sustainability.",
      policy: "Environmental & Resource Management",
      example: "Example: Reduced regulations vs. environmental protection"
    },

    // Immigration & Demographics
    {
      text: "Immigration levels should be significantly reduced.",
      weight: 1,
      proView: "Lower immigration preserves social cohesion and resources.",
      conView: "Immigration enriches society and drives economic growth.",
      policy: "Immigration & Demographics",
      example: "Example: Restricted immigration vs. open borders"
    },
    {
      text: "Citizenship should be easily accessible to all residents.",
      weight: -1,
      proView: "Easy paths to citizenship promote integration and equality.",
      conView: "Citizenship should be earned through strict criteria.",
      policy: "Immigration & Demographics",
      example: "Example: Easy naturalization vs. strict requirements"
    },

    // Education & Innovation
    {
      text: "Public funding for higher education should be increased significantly.",
      weight: -1,
      proView: "Higher education should be accessible to all regardless of wealth.",
      conView: "Individual students should bear the cost of their education.",
      policy: "Education & Innovation",
      example: "Example: Free college vs. student loans"
    },
    {
      text: "School choice and voucher programs should be expanded.",
      weight: 1,
      proView: "School choice improves education through competition.",
      conView: "School choice undermines public education.",
      policy: "Education & Innovation",
      example: "Example: School vouchers vs. public school focus"
    },

    // Monetary & Fiscal Policy
    {
      text: "The central bank should prioritize full employment over inflation control.",
      weight: -1,
      proView: "Employment is more important than price stability.",
      conView: "Controlling inflation should be the primary monetary goal.",
      policy: "Monetary & Fiscal Policy",
      example: "Example: Employment focus vs. inflation targeting"
    },
    {
      text: "Government spending should be strictly limited by law.",
      weight: 1,
      proView: "Spending caps ensure fiscal responsibility.",
      conView: "Flexible spending enables response to economic needs.",
      policy: "Monetary & Fiscal Policy",
      example: "Example: Balanced budget amendment vs. flexible spending"
    },

    // Labor & Employment
    {
      text: "The minimum wage should be significantly increased.",
      weight: -1,
      proView: "Higher minimum wages reduce poverty and inequality.",
      conView: "Minimum wage increases cause unemployment.",
      policy: "Labor & Employment",
      example: "Example: Living wage vs. market-based wages"
    },
    {
      text: "Employment regulations should be minimized.",
      weight: 1,
      proView: "Less regulation creates more jobs and opportunities.",
      conView: "Worker protections are essential for fair treatment.",
      policy: "Labor & Employment",
      example: "Example: Flexible labor markets vs. worker protections"
    },

    // Trade & International Relations
    {
      text: "Trade agreements should require strong labor and environmental standards.",
      weight: -1,
      proView: "Trade must promote global standards and protections.",
      conView: "Trade requirements hurt economic growth and development.",
      policy: "Trade & International Relations",
      example: "Example: Regulated trade vs. free trade"
    },
    {
      text: "Tariffs should be used to protect domestic industries.",
      weight: 1,
      proView: "Tariffs protect domestic jobs and industries.",
      conView: "Free trade benefits consumers and promotes growth.",
      policy: "Trade & International Relations",
      example: "Example: Protectionist tariffs vs. free trade"
    }
  ],
  situational: [
    {
      text: "You're a small business owner. Your main competitor is offering lower prices by possibly using questionable labor practices. What do you do?",
      policy: "Economic System & Market Regulation",
      choices: [
        { text: "Report them to regulatory authorities for investigation", value: -2 },
        { text: "Lower your own prices and reduce staff to compete", value: 2 },
        { text: "Maintain your prices but advertise your ethical practices", value: 0 },
        { text: "Form a local business association to establish industry standards", value: -1 }
      ]
    },
    {
      text: "Your neighborhood is installing security cameras. As the residents' association president, you need to decide on their usage. What's your decision?",
      policy: "Rights & Individual Liberty",
      choices: [
        { text: "Install cameras only at entrance points with minimal retention", value: -1 },
        { text: "Implement comprehensive coverage with facial recognition", value: 2 },
        { text: "Reject the camera proposal entirely", value: -2 },
        { text: "Install cameras but with strict access controls", value: 1 }
      ]
    },
    {
      text: "You're on the school board. The district has a budget surplus. How should it be used?",
      policy: "Public Services & Social Safety",
      choices: [
        { text: "Expand free lunch and after-school programs", value: -2 },
        { text: "Return it to taxpayers through a rate reduction", value: 2 },
        { text: "Split between programs and tax reduction", value: 0 },
        { text: "Create a public-private partnership for services", value: 1 }
      ]
    },
    {
      text: "As a city council member, you're voting on the police department budget. Crime is up slightly, but so are complaints about over-policing. What's your vote?",
      policy: "Security & Defense",
      choices: [
        { text: "Increase funding for more officers and equipment", value: 2 },
        { text: "Redirect funds to community programs and social workers", value: -2 },
        { text: "Maintain current funding but require more training", value: 0 },
        { text: "Increase funding specifically for community policing", value: -1 }
      ]
    },
    {
      text: "Your company can switch to green energy, but it will increase costs by 15%. As CEO, what do you implement?",
      policy: "Environmental & Resource Management",
      choices: [
        { text: "Switch immediately and absorb the costs", value: -2 },
        { text: "Maintain current energy sources to stay competitive", value: 2 },
        { text: "Gradually transition as costs decrease", value: 0 },
        { text: "Switch only if tax incentives are available", value: 1 }
      ]
    },
    {
      text: "As a hiring manager, you have two equally qualified candidates for a position. One is a local graduate, the other requires visa sponsorship. Who do you choose?",
      policy: "Immigration & Demographics",
      choices: [
        { text: "Hire the international candidate to increase team diversity", value: -2 },
        { text: "Choose the local candidate to support the community", value: 2 },
        { text: "Create two positions to hire both if possible", value: -1 },
        { text: "Select based solely on interview performance", value: 0 }
      ]
    },
    {
      text: "You're a teacher with a student struggling due to their family's financial situation. How do you handle it?",
      policy: "Education & Innovation",
      choices: [
        { text: "Advocate for more school-provided support services", value: -2 },
        { text: "Refer them to private tutoring and assistance programs", value: 2 },
        { text: "Work with local charities to provide support", value: 0 },
        { text: "Provide extra help within existing school resources", value: -1 }
      ]
    },
    {
      text: "Your local government is facing a budget deficit. As a council member, how do you address it?",
      policy: "Monetary & Fiscal Policy",
      choices: [
        { text: "Increase property taxes to maintain all services", value: -2 },
        { text: "Cut services to avoid any tax increases", value: 2 },
        { text: "Issue bonds for temporary funding", value: -1 },
        { text: "Implement mixed spending cuts and fee increases", value: 0 }
      ]
    },
    {
      text: "As a department manager, your employees are asking to form a union. How do you respond?",
      policy: "Labor & Employment",
      choices: [
        { text: "Actively support their organizing efforts", value: -2 },
        { text: "Discourage unionization through incentives", value: 2 },
        { text: "Remain neutral but improve current benefits", value: 0 },
        { text: "Create an employee committee instead", value: 1 }
      ]
    },
    {
      text: "Your family business can save money by moving production overseas. What's your decision as owner?",
      policy: "Trade & International Relations",
      choices: [
        { text: "Keep production local despite higher costs", value: 2 },
        { text: "Relocate all production to reduce costs", value: -2 },
        { text: "Gradually shift some production while maintaining local presence", value: 0 },
        { text: "Partner with local businesses to share costs", value: 1 }
      ]
    }
  ]
};

