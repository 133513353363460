import React, { useEffect } from 'react'
import { Analytics } from "@vercel/analytics/react"
import PoliticalQuiz from './components/PoliticalQuiz'

function App() {
  // Function to update meta tags dynamically
  const updateMetaTags = (title, description) => {
    document.title = title;
    
    // Update primary meta tags
    document.querySelector('meta[name="title"]').setAttribute('content', title);
    document.querySelector('meta[name="description"]').setAttribute('content', description);
    
    // Update OG tags
    document.querySelector('meta[property="og:title"]').setAttribute('content', title);
    document.querySelector('meta[property="og:description"]').setAttribute('content', description);
    
    // Update Twitter tags
    document.querySelector('meta[property="twitter:title"]').setAttribute('content', title);
    document.querySelector('meta[property="twitter:description"]').setAttribute('content', description);
  };

  useEffect(() => {
    // Set default meta tags when component mounts
    updateMetaTags(
      'Political Spectrum Quiz - Where Do You Stand?',
      'Discover your true political alignment with our scientific quiz. Are you conservative, liberal, or somewhere in between?'
    );
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8 text-gray-900">
          Where Are You On The Political Spectrum?
        </h1>
        <PoliticalQuiz />
        <Analytics />
      </div>
    </div>
  )
}

export default App
