import { useState, useEffect } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import { Area, AreaChart, ResponsiveContainer, ReferenceLine, XAxis, YAxis, Tooltip } from 'recharts';
import { ArrowRight, RotateCcw, Info } from 'lucide-react';
import { questions } from '../data/questions';
import { distributionData } from '../data/mockData';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertDescription } from './ui/alert';
import { Share2, Twitter, Facebook, Link } from 'lucide-react';

const PolicyBarChart = ({ policy, score }) => {
  const position = ((score + 4) / 8) * 100;
  const clampedPosition = Math.min(Math.max(position, 0), 100);
    
  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 mb-4">
      <span className="w-full sm:w-48 text-sm font-medium">{policy}</span>
      <div className="relative w-full h-8">
        <div className="absolute inset-0 bg-gray-100 rounded-full">
          <div className="absolute top-1/2 -translate-y-1/2 w-3 h-3 rounded-full transition-all"
               style={{ 
                 left: `${clampedPosition}%`,
                 backgroundColor: '#A9A9A9',
                 transform: 'translate(-50%, -50%)'
               }} />
        </div>
        <div className="absolute top-full pt-1 w-full flex justify-between text-xs text-gray-500">
          <div className="absolute left-0 h-2 w-px bg-gray-300" style={{ top: '-4px' }} />
          <div className="absolute left-1/4 h-2 w-px bg-gray-300" style={{ top: '-4px' }} />
          <div className="absolute left-1/2 h-2 w-px bg-gray-300" style={{ top: '-4px' }} />
          <div className="absolute left-3/4 h-2 w-px bg-gray-300" style={{ top: '-4px' }} />
          <div className="absolute right-0 h-2 w-px bg-gray-300" style={{ top: '-4px' }} />
        </div>
      </div>
    </div>
  );
};

const PolicyPopularity = ({ answers, questions }) => {
  const policyScores = questions.reduce((acc, q, i) => {
    if (!acc[q.policy]) {
      acc[q.policy] = { total: 0, count: 0 };
    }
    acc[q.policy].total += answers[i];
    acc[q.policy].count += 1;
    return acc;
  }, {});

  const sortedPolicies = Object.entries(policyScores)
    .map(([policy, scores]) => ({
      policy,
      score: scores.total / scores.count
    }))
    .sort((a, b) => Math.abs(b.score) - Math.abs(a.score));

  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold mb-6">Your Political Spectrum per Policy</h3>
      <div className="space-y-4">
        {sortedPolicies.map(({ policy, score }) => (
          <PolicyBarChart key={policy} policy={policy} score={score} />
        ))}
      </div>
    </div>
  );
};

const DistributionChart = ({ quizType, userScore }) => {
  const [distribution, setDistribution] = useState([]);

  useEffect(() => {
    const fetchDistribution = async () => {
      try {
        const response = await fetch(`/api/distribution?type=${quizType}`);
        const data = await response.json();
        setDistribution(data || []);
      } catch (error) {
        console.error("Fetch error:", error);
        setDistribution([]);
      }
    };
    fetchDistribution();
  }, [quizType]);

  return (
    <div className="h-72 w-full relative">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={distribution}>
          <XAxis
            dataKey="position"
            domain={[-100, 100]}
            tick={false}
          />
          <YAxis hide />
          <Area
            dataKey="count"
            stroke="#3b82f6"
            fill="#93c5fd"
            fillOpacity={0.4}
          />
          {typeof userScore === "number" && !isNaN(userScore) && (
            <ReferenceLine
              x={userScore}
              stroke="#ef4444"
              strokeWidth={2}
              label={{
                position: "insideTop",
                offset: 20,
                value: "Your Position",
                fill: "#ef4444",
                fontSize: 12,
              }}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      {/* Bottom Legend */}
      <div className="absolute bottom-0 left-0 right-0 flex justify-between text-sm text-gray-600 px-2">
        <span>Far Left</span>
        <span>Left</span>
        <span>Center Left</span>
        <span>Center</span>
        <span>Center Right</span>
        <span>Right</span>
        <span>Far Right</span>
      </div>
    </div>
  );
};

const QuestionCard = ({ text, example, showPerspectives, proView, conView, onAnswer, onTogglePerspectives }) => (
  <Card className="w-full max-w-2xl mx-auto shadow-lg">
    <CardContent className="p-8">
      <h3 className="text-xl font-semibold mb-4">{text}</h3>
      <p className="text-gray-600 text-sm italic mb-6">{example}</p>
      
      <Button 
        variant="blue" 
        className="w-full mb-6 bg-blue-500 hover:bg-blue-600"
        onClick={onTogglePerspectives}
      >
        <Info className="mr-2 h-4 w-4" />
        {showPerspectives ? "Hide" : "Show"} Different Perspectives
      </Button>

      {showPerspectives && (
        <div className="space-y-4 mb-6">
          <Alert className="bg-blue-50 border-blue-200">
            <AlertDescription className="text-blue-800">
              <strong>Supporting view:</strong> {proView}
            </AlertDescription>
          </Alert>
          <Alert className="bg-gray-50 border-gray-200">
            <AlertDescription className="text-gray-800">
              <strong>Opposing view:</strong> {conView}
            </AlertDescription>
          </Alert>
        </div>
      )}

      <div className="grid gap-3">
        {[-2, -1, 0, 1, 2].map((value) => (
          <Button
            key={value}
            className="w-full py-6 text-lg font-medium transition-all hover:scale-[1.02]"
            onClick={() => onAnswer(value)}
            variant={value === 0 ? "outline" : "default"}
          >
            {value === -2 ? "Strongly Disagree" :
             value === -1 ? "Disagree" :
             value === 0 ? "Neutral" :
             value === 1 ? "Agree" :
             "Strongly Agree"}
            <ArrowRight className="ml-2 h-5 w-5" />
          </Button>
        ))}
      </div>
    </CardContent>
  </Card>
);

const submitScore = async (score, quizType, captchaToken) => {
  try {
    if (!captchaToken) {
      setCaptchaError(true);
      alert('Please complete the security check!');
      return false;
    }

    // First verify CAPTCHA
    const verification = await fetch('/api/verify-captcha', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: captchaToken })
    });
    
    if (!verification.ok) {
      throw new Error('CAPTCHA verification failed');
    }

    // Then submit score
    const response = await fetch('/api/submit-score', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ score, quizType })
    });
    
    if (!response.ok) {
      const data = await response.json();
      if (data.error === 'You have already taken this quiz') {
        alert('You have already taken this quiz. Try the other version!');
        reset();
        return false;
      }
      throw new Error(data.error);
    }
    return true;
    
  } catch (error) {
    console.error('Submission error:', error);
    setCaptchaToken(null);
    setCaptchaError(true);
    setServerError(error.message);
    return false;
  }
};

const SocialShare = ({ score, position }) => {
  const shareText = `I just took the Political Spectrum Quiz and I scored: ${score.toFixed(1)}. Where do you stand? #PoliticalSpectrumQuiz`;
  
  const shareUrl = window.location.origin;
  
  const shareToTwitter = () => {
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank');
  };
  
  const shareToFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(url, '_blank');
  };
  
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="mt-8 space-y-4">
      <h3 className="text-xl font-bold flex items-center gap-2">
        <Share2 className="h-5 w-5" />
        Share Your Results
      </h3>
      
      <div className="grid grid-cols-3 gap-4">
        <Button variant="black"
          onClick={shareToTwitter}
          className="flex items-center justify-center gap-2 bg-[#1DA1F2] hover:bg-[#1a8cd8]"
        >
          <Twitter className="h-5 w-5" />
          X / Twitter
        </Button>
        
        <Button variant="blue"
          onClick={shareToFacebook}
          className="flex items-center justify-center gap-2 bg-[#4267B2] hover:bg-[#365899]"
        >
          <Facebook className="h-5 w-5" />
          Facebook
        </Button>
        
        <Button variant="outline"
          onClick={copyLink}
          variant="outline"
          className="flex items-center justify-center gap-2"
        >
          <Link className="h-5 w-5" />
          Copy Link
        </Button>
      </div>
    </div>
  );
};

const PoliticalQuiz = () => {
  const [quizType, setQuizType] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showPerspectives, setShowPerspectives] = useState(false);

  const isSituational = quizType === 'situational';

  const currentQuestions = quizType ? questions[quizType] : [];
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const [serverError, setServerError] = useState('');

  // Add reCAPTCHA verification
  const handleCaptcha = (token) => {
    setCaptchaVerified(!!token);
  };

  const getQuizDescription = (type) => {
    switch(type) {
      case 'short':
        return '5 questions on core policy areas';
      case 'medium':
        return '10 questions covering major policy areas';
      case 'detailed':
        return '20 questions for a comprehensive analysis';
      case 'situational':
        return '10 real-world scenarios to test your views';
      default:
        return '';
    }
  };
  
  const calculateResult = (userAnswers) => {
    if (!Array.isArray(currentQuestions) || !Array.isArray(userAnswers)) return 0;
    if (currentQuestions.length === 0 || userAnswers.length === 0) return 0;
    
    const validAnswers = userAnswers.filter(answer => answer !== null && answer !== undefined);
    if (validAnswers.length === 0) return 0;
    
    const total = validAnswers.reduce((sum, val) => sum + val, 0);
    const maxPossible = isSituational ? (currentQuestions.length * 2) : (currentQuestions.length * 2);
    return Math.round((total / maxPossible) * 100);
  };

  const getPosition = (score, hasExtremeAnswers) => {
    if (hasExtremeAnswers && score <= -65) return "Far Left (Authoritarian)";
    if (hasExtremeAnswers && score >= 65) return "Far Right (Authoritarian)";
    if (score < -85) return "Far Left";
    if (score < -45) return "Left";
    if (score < -15) return "Center Left";
    if (score < 15) return "Centrist";
    if (score < 45) return "Center Right";
    if (score < 85) return "Right";
    return "Far Right";
  };

  const handleAnswer = async (value) => {
    if (!currentQuestions?.[currentQuestion]) return;
    
    // For situational questions, use the value directly since it's pre-weighted in the choices
    const newAnswers = [...answers, isSituational ? value : value * currentQuestions[currentQuestion].weight];
    setAnswers(newAnswers);
  
    if (currentQuestion === currentQuestions.length - 1) {
      const score = calculateResult(newAnswers);
      const submitted = await submitScore(score, quizType, captchaToken);
      if (submitted) {
        setShowResults(true);
      }
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  if (showResults) {
    const score = calculateResult(answers);
    const hasExtremeAnswers = answers.some((answer, index) => 
      currentQuestions?.[index]?.extreme === true && Math.abs(answer) >= 4
    );
    const position = getPosition(score, hasExtremeAnswers);

    return (
      <Card className="w-full max-w-3xl mx-auto">
        <CardContent className="p-8">

          {!quizType && (
            <div className="my-4 text-center">
              
            </div>
          )}
      
          {/* Turnstile placement - before quiz submission */}
          {!showResults && quizType && (
            <div className="flex items-center justify-center gap-4">
              <p className="text-gray-700 font-medium">Checking you are a human!</p>
              <Turnstile
                siteKey={'0x4AAAAAAA7MXW4GUXRSO5bg'}
                onSuccess={(token) => {
                  setCaptchaToken(token);
                  setCaptchaError(false);
                }}
                onError={() => setCaptchaError(true)}
                options={{
                  size: 'compact',
                  theme: window.matchMedia('(prefers-color-scheme: dark)').matches 
                    ? 'dark' 
                    : 'light'
                }}
                className="mx-auto w-full max-w-[300px]"
              />
              {captchaError && (
                <p className="text-red-500 text-sm mt-2 text-center">
                  Security verification required to submit!
                </p>
              )}
            </div>
          )}
          
          <h2 className="text-2xl font-bold mb-8">Your Results</h2>
          <div className="space-y-6">
            <div>
              <p className="text-xl mb-4">
                Your position: <span className="font-semibold">{position}</span>
              </p>
              <p className="text-xl mb-4">
                Your score: <span className="font-semibold">{Math.round(score)}</span>
              </p>
              <div className="relative w-full h-4 bg-gray-100 rounded-full mb-12">
                <div 
                  className="absolute top-0 w-1 h-8 bg-blue-600"
                  style={{ 
                    left: `${Math.min(Math.max((score + 100) / 2, 0), 100)}%`,
                    transform: 'translateX(-50%)'
                  }}
                />
                <div className="absolute top-10 left-0 right-0 flex justify-between text-sm text-gray-600">
                  <span>Far Left</span>
                  <span>Left</span>
                  <span>Center Left</span>
                  <span>Center</span>
                  <span>Center Right</span>
                  <span>Right</span>
                  <span>Far Right</span>
                </div>
              </div>
            </div>

            <br></br>
            <PolicyPopularity 
              answers={answers} 
              questions={currentQuestions} 
            />
            
            <div>
              <h3 className="text-xl font-bold mb-4">Overall Response Distribution</h3>
              <DistributionChart 
                quizType={quizType}
                userScore={score}
              />
            </div>

            <SocialShare 
              score={score} 
              position={position}
            />
            
            <Button variant="green"
              className="w-full mt-8 bg-gray-900 text-white hover:bg-gray-800" 
              onClick={() => {
                setQuizType(null);
                setCurrentQuestion(0);
                setAnswers([]);
                setShowResults(false);
                setShowPerspectives(false);
              }}
            >
              <RotateCcw className="mr-2 h-4 w-4" />
              Take Another Quiz
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Rest of the component implementation...
  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardContent className="p-6">
        {!showResults && (
          <>
            <div className="mt-6">
              <Turnstile
                siteKey="0x4AAAAAAA7MXW4GUXRSO5bg"
                onSuccess={setCaptchaToken}
                options={{
                  size: 'compact',
                  theme: 'light'
                }}
                className="mx-auto"
              />
            </div>
            <div className="my-6 text-center">
            </div>
          </>
        )}
        
        {!quizType ? (
          <>
            <h2 className="text-2xl font-bold mb-4">Answer These Questions to Find Out!</h2>
            <div className="bg-gray-50 p-4 rounded-lg mt-4">
              <p className="text-sm text-gray-600">
                Join millions of others in discovering where you stand on the political spectrum! 
                Take the quiz that's gone viral across social media.
              </p>
            </div>
            <p className="text-gray-600 mb-6">Choose any quiz to get started!</p>
            <div className="space-y-4">
              <Button 
                className="w-full flex flex-col items-start p-4" 
                onClick={() => setQuizType('short')}
              >
                <span className="font-semibold">Short Quiz</span>
                <span className="text-sm text-gray-400">{getQuizDescription('short')}</span>
              </Button>
              <Button 
                className="w-full flex flex-col items-start p-4" 
                onClick={() => setQuizType('medium')}
              >
                <span className="font-semibold">Medium Quiz</span>
                <span className="text-sm text-gray-400">{getQuizDescription('medium')}</span>
              </Button>
              <Button 
                className="w-full flex flex-col items-start p-4" 
                onClick={() => setQuizType('detailed')}
              >
                <span className="font-semibold">Detailed Quiz</span>
                <span className="text-sm text-gray-400">{getQuizDescription('detailed')}</span>
              </Button>
              <Button 
                className="w-full flex flex-col items-start p-4" 
                onClick={() => setQuizType('situational')}
              >
                <span className="font-semibold">Situational Quiz</span>
                <span className="text-sm text-gray-400">{getQuizDescription('situational')}</span>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <h2 className="text-2xl font-bold">
                Question {currentQuestion + 1} of {currentQuestions.length}
              </h2>
              <div className="w-full h-2 bg-gray-200 rounded-full mt-2">
                <div 
                  className="h-full bg-blue-500 rounded-full transition-all"
                  style={{ width: `${(currentQuestion / currentQuestions.length) * 100}%` }}
                />
              </div>
            </div>
            <p className="text-lg mb-6">{currentQuestions[currentQuestion]?.text}</p>
            
            {!isSituational && (
              <>
                <p className="text-sm text-gray-600 mb-4">{currentQuestions[currentQuestion]?.example}</p>
                <Button 
                  variant="blue" 
                  className="w-full mb-4"
                  onClick={() => setShowPerspectives(!showPerspectives)}
                >
                  <Info className="mr-2 h-4 w-4" />
                  {showPerspectives ? "Hide" : "Show"} Different Perspectives
                </Button>
                {showPerspectives && currentQuestions[currentQuestion] && (
                  <div className="space-y-3 mb-6">
                    <Alert>
                      <AlertDescription>
                        <strong>Supporting view:</strong> {currentQuestions[currentQuestion].proView}
                      </AlertDescription>
                    </Alert>
                    <Alert>
                      <AlertDescription>
                        <strong>Opposing view:</strong> {currentQuestions[currentQuestion].conView}
                      </AlertDescription>
                    </Alert>
                  </div>
                )}
              </>
            )}
        
            <div className="space-y-3">
              {isSituational ? (
                currentQuestions[currentQuestion]?.choices.map((choice, index) => (
                  <Button
                    key={index}
                    className="w-full text-left py-4 px-6"
                    onClick={() => handleAnswer(choice.value)}
                  >
                    {choice.text}
                  </Button>
                ))
              ) : (
                [-2, -1, 0, 1, 2].map((value) => (
                  <Button
                    key={value}
                    className="w-full"
                    onClick={() => handleAnswer(value)}
                  >
                    {value === -2 ? "Strongly Disagree" :
                     value === -1 ? "Disagree" :
                     value === 0 ? "Neutral" :
                     value === 1 ? "Agree" :
                     "Strongly Agree"}
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                ))
              )}
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PoliticalQuiz;
